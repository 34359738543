@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
} */

.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
  margin: 0 auto;
}

.completion {
  @apply underline text-[#0049EE] cursor-pointer;
}

.Toastify__toast-body {
  align-items: start !important;
}

.ant-select > .ant-select-arrow {
  @apply !text-[#808080];
}
.text-white-85 {
  @apply text-[#FFFFFFD9];
}
.otp-input {
  width: 100%;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  line-height: 1;
}
.fpBGKh {
  border-radius: 0px !important;
}

/* .rdt_TableCol_Sortable span {
	opacity: 1;
} */

.font-manrope {
  font-family: 'Manrope', sans-serif !important;
}
.text-h2 {
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
}

.text-h4 {
  /* font-family: Inter; */
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.4000000059604645px;
  text-align: center;
}

.text-body1 {
  /* font-family: Inter; */
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}
.text-helper {
  /* font-family: Inter; */
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.status-case-page.ant-select-open .ant-select-selector,
.status-case-page.ant-select-focused .ant-select-selector,
.status-case-page .ant-select-selector:focus,
.status-case-page .ant-select-selector:hover,
.status-case-page .ant-select-selector:active {
  border-color: #828282 !important;
  background-color: #f5f5f5 !important;
}

.note-card-expand {
  transition: border 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.note-card-edit {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}
.markdown {
  table {
    border-collapse: collapse;
  }

  th,
  td {
    padding: 6px 13px;
    border: 1px solid black;
  }

  p {
    line-height: 1.5;
  }
}

.highlight {
  background-color: yellow;
}
