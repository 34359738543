/* Reset HTML */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Inter" serif; */
  font-family: 'Manrope', sans-serif;
  font-style: normal;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Chrome, Edge, and Safari */
::-moz-scrollbar-button,
::-webkit-scrollbar-button {
  width: 0px;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  /* border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d62929; */
  border-radius: 14px;
  background-color: #828282;
}

/* testing */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bookmark__list {
  list-style-type: square !important;
}

.rpv-bookmark__item {
  margin-bottom: 5px !important;
  border-radius: 8px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  /* align-items:first baseline !important; */
}

.rpv-bookmark__item:hover,
.rpv-bookmark__item:focus {
  border-radius: 8px !important;
  background-color: #ededef !important;
}

/* .rpv-bookmark__list li .rpv-bookmark__list {
  display: none !important;
} */

/* .rpv-bookmark__toggle {
  display: none !important;
} */

.rpv-thumbnail__item {
  padding: 5px !important;
  border-radius: 5px !important;
}

/* .rpv-thumbnail__item--single:hover,
.rpv-thumbnail__item--dual-cover:hover,
.rpv-thumbnail__items--dual:hover .rpv-thumbnail__item,
.rpv-thumbnail__items--dual-cover:hover .rpv-thumbnail__item,
.rpv-thumbnail__item:focus, 
.rpv-thumbnail__items--selected .rpv-thumbnail__item {
  background-color: #EDEDEF !important;
} */ 

[data-placeholder]:empty:before{
  content: attr(data-placeholder);
  color: #888;
  font-family: 'Manrope', sans-serif;
}